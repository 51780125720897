import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import Anchorlink from '../../components/Anchorlink';

const TagsSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px;
`;

const TagsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 100px;
`;

const TagsTitle = styled.h1`
  font-size: 26px;
  margin: 0 0 50px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.five};
  position: relative;
`;

const ListContainer = styled.div`
  margin-bottom: 2rem;
`;

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-right: 15px;
`;

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <TagsSection>
      <Helmet title={`Tags | ${title}`} />
      <TagsContainer>
        <ListContainer>
          <TagsTitle>Tags</TagsTitle>
          <MenuList>
            {group.map((tag) => (
              <ListItem key={tag.fieldValue}>
                <Anchorlink
                  ariaLabel="tag anchor"
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}
                >
                  {tag.fieldValue} ({tag.totalCount})
                </Anchorlink>
              </ListItem>
            ))}
          </MenuList>
        </ListContainer>
      </TagsContainer>
    </TagsSection>
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
  ]).isRequired,
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
